import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";

export default {
    name: "contact.component",
    components: {
        FontAwesomeIcon
    },
    data(){
        return{
            socialIcons:[
                {
                    id: 1,
                    name: require("../../assets/images/facebook.svg"),
                    linkTo: 'https://www.facebook.com/'
                },
                {
                    id: 2,
                    name: require("../../assets/images/telegram.svg"),
                    linkTo: 'https://web.telegram.org/'
                },
                {
                    id: 3,
                    name: require("../../assets/images/twitter.svg"),
                    linkTo: 'https://twitter.com/?lang=en'
                },
                {
                    id: 4,
                    name: require("../../assets/images/youtube.svg"),
                    linkTo: 'https://www.youtube.com/'
                },
                {
                    id: 5,
                    name: require("../../assets/images/instagram.svg"),
                    linkTo: 'https://www.instagram.com/'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            contact: 'contact/_getContacts',
            local: '_getLocal',
        }),
    },
    created() {
        this.$store.dispatch('contact/getProjects')
    }
}
