<style lang="scss" src="./contact.component.scss"></style>

<template>
  <div class="contact mt-83">
    <div class="contact_bg"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="contact_info">
            <div class="contact_info__mail" v-for="(item, index) in contact" :key="index">
              <h5>{{local.contact.mail}}</h5>
              <h1 class="mt-15">{{item.email}}</h1>
              <p class="mt-15">{{local.contact.textOne}} <a :href="'tel:'+item.phone">{{local.contact.textTwo}}</a> {{local.contact.textThree}}</p>
            </div>
            <div class="contact_info__social">
              <ul>
                <li v-for="iconList in socialIcons" :key="iconList.id">
                  <a :href="iconList.linkTo" target="_blank">
                    <img :src="iconList.name" alt="">
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-for="(item, index) in contact" :key="index">
        <div class="col-lg-7 col-md-7 col-sm-12 col-12">
          <div class="contact_location">
            <h5>{{local.contact.location}}</h5>
            <p>{{item.location}}</p>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 col-12">
          <div class="contact_phone">
            <h5>{{local.contact.phone}}</h5>
            <p>{{item.phone}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="contact_map mt-119">
            <iframe
                width="100%"
                height="429"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.0626048311847!2d49.85208261564821!3d40.38530506547349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d0dce8844ef%3A0x86c6fd9527bc4d18!2zWWHFn8SxbCBCYXphcg!5e0!3m2!1sen!2s!4v1644864183013!5m2!1sen!2s" allowfullscreen="" loading="lazy">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./contact.component.js"></script>
